import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { glChartCols, glChartReactTableCols } from '../constants/GlCols'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingCircle from '../components/common/LoadingCircle'
import TableMui from '../components/TableMui'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingButton from '../components/LoadingButton'
import ReactTable from '../components/ReactTable'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import DashboardAthenaTableClientSide from '../components/DashboardAthenaTableClientSide'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function GlAccounts (props) {
  const [state, setstate] = useState({
    glSelect: '',
    glOptions: [],
    chartData: null,
    fetched: true,
    isLoading: false
  })
  const [showTable, setShowTable] = useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const fetch = async id => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'gl-form',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'chart-account-read',
          chartName: id
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        chartData: resp.data.chartAccounts,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   if (!state.glSelect) return null
  //   fetch(state.glSelect)
  // }

  const handleSubmit = e => {
    e.preventDefault()
    setShowTable(true)
  }

  
  const options = getTableOptions({
    cols: glChartCols,
    fileName: `GL_Account_${state.glSelect}`
  })

  options['responsive'] = 'scrollMaxHeight'

  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Accounts
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
              <AutocompleteDropDown
                  {...props}
                  id={'glSelect'}
                  name={'glSelect'}
                  width='300px'
                  required={true}
                  variant='standard'
                  label={'Chart'}
                  value={state.glSelect}
                  category={'chart'}
                  screen={'gl-accounts'}
                  onChange={val =>
                    setstate({...state, glSelect: val ? val.key_value : '' })
                  }
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>
        
        {showTable && (
          <div style={{ marginTop: '1rem ' }}>
        {/* <DashboardAthenaTableServerSide
              {...props}
              params={{chartName: state.glSelect }}
               tableColumns={glChartReactTableCols}
              reportName='gl_chart_acounts_read'
              tableTitle={''}
              callback={() => console.log('callback')}
              noPagination
            /> */}

<DashboardAthenaTableClientSide 
{...props} 
key={state.glSelect}
cols={glChartReactTableCols}  
queryName={'gl_chart_acounts_read'}
headers={{chartName: state.glSelect }}
                         />
</div>
        )}

        {/* {state.chartData && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={glChartReactTableCols} data={state.chartData} />
          </Grid>
        )} */}
      </div>
    </div>
  )
}
