import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LambdaFetch from '../functions/FetchFromLambda';
import LoadingButton from '../components/LoadingButton';
import { billCostCols, billofCostAcParams } from '../constants/ActivityCols';
import TableMui from '../components/TableMui';
import getTableOptions from '../constants/TableOptions';
import DialogTable from '../components/DialogTable';
import { smallScreenWidth } from '../constants/AppConstants';
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2';
import ReactTable from '../components/ReactTable';
import ReactTableServerSide from '../components/ReactTableServerSide';
import AutocompleteDropDown from '../components/AutocompleteDropdown';
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem',
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem',
  },
}));

const defaultHeaders = {
  fiscalYear: '',
  period: '',
  company: '',
  activity: '',
  activityGrp: '',
  acctCategory: '',
  fromDate: '',
  toDate: '',
};

export default function BillofCostAcParams(props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    tableKey: JSON.stringify(defaultHeaders),
  });
  const [showTable, setShowTable] = useState(false);
  const [headers, setHeaders] = useState(defaultHeaders);
  const [clearHeader, setClearHeaders] = React.useState(false);
  const [showHeaders, setShowHeaders] = useState(false);
  const classes = useStyles();

  const { credentials } = props.fetchInitialData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (JSON.stringify(headers) === state.tableKey) return null;
    setShowTable(true);
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) });
  };

  const fetchBillCostData = async (params) => {
    try {
      const resp = await LambdaFetch(
        'bill-cost',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-bill-cost-ac-params',
          ...params,
        }),
        '',
        credentials
      );

      console.log();
      setstate({
        ...state,
        data: resp.data.billCostData,
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      setstate({ ...state, isLoading: false });
    }
  };
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false });
      setShowTable(false);
      return null;
    }
    console.log('callback');
    setstate({ ...state, isLoading: false });
  };

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size="small"
        onClick={() => props.history.push('/')}
        aria-label="delete"
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Bill of Cost
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'fiscalYear'}
                  width="125px"
                  key={'fiscalYear'}
                  required={true}
                  variant="standard"
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'bill-cost'}
                  onChange={(val) =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : null,
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'period'}
                  name={'period'}
                  width="125px"
                  key={'period'}
                  required={true}
                  variant="standard"
                  label={'Period'}
                  value={headers.period}
                  category={'period'}
                  screen={'bill-cost'}
                  onChange={(val) =>
                    setHeaders({
                      ...headers,
                      period: val ? val.key_value : null,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              {[
                {
                  id: 'company',
                  dataField: 'COMPANY',
                  label: 'Company',
                  dateType: 'number',
                  isRequired: false,
                },
                {
                  id: 'activity',
                  dataField: 'ACTIVITY',
                  label: 'Activity',
                  dateType: 'string',
                  isRequired: false,
                },
                {
                  id: 'activityGrp',
                  dataField: 'ACTIVITY_GRP',
                  label: 'Activity Group',
                  dateType: 'string',
                  isRequired: false,
                },
                {
                  id: 'acctCategory',
                  dataField: 'ACCT_CATEGORY',
                  label: 'Acct Category',
                  dateType: 'string',
                  isRequired: false,
                },
              ].map((field) => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={!(headers.fiscalYear && headers.period)}
                      required={field.isRequired}
                      id={field.id}
                      width="125px"
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant="standard"
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id });
                      }}
                      credentials={credentials}
                      apiResource="actrans"
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-actrans',
                        ...headers,
                      }}
                    />
                  </Grid>
                );
              })}
              {[
                {
                  id: 'fromDate',
                  label: 'From Date',
                },
                {
                  id: 'toDate',
                  label: 'To Date',
                },
              ].map((field) => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{ marginTop: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant="standard"
                      value={headers.id}
                      fullWidth
                      onChange={(e) => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label="Submit"
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color="primaryVLButton"
                buttonType="submit"
              />
              {!!(
                headers.fiscalYear ||
                headers.period ||
                headers.activity ||
                headers.activityGrp ||
                headers.acctCategory ||
                headers.company ||
                headers.fromDate ||
                headers.toDate
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader);
                    setHeaders(defaultHeaders);
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>
        {/* {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action="get-bill-cost-ac-params"
              apiResource={'bill-cost'}
              transform={(r) => r}
              tableTitle={''}
              tableColumns={billofCostAcParams}
              callback={handleCallback}
              noPagination
              downloadAction={'get-bill-cost-ac-params-download'}
            />
          </div>
        )} */}

{showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <DashboardAthenaTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              tableColumns={billofCostAcParams}
              reportName='bill_of_costs_ac_params'
              tableTitle={''}
              transform={(r) => r}
              callback={handleCallback}
            />
          </div>
        )}
      </div>
    </div>
  );
}
