import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import {
  poDetailCols,
  poSourceLineCols,
  poReceiveCols,
  poRecLineCols,
  // poDetailReactTableCols,
  // poReceiveReactTableCols,
  poRecLineReactTableCols,
  poSourceLineReactTableCols,
} from '../constants/PoCols';
import getTableOptions from '../constants/TableOptions';
import TableMui from '../components/TableMui';
import { Button } from '@material-ui/core';
import { currencyTextFormat } from '../utils/formatter';
import LambdaFetch from '../functions/FetchFromLambda';
import DialogTable from './DialogTable';
import { dateFormat } from '../utils/formatter';
import { AntTabs } from '../components/VendorTabs';
import ReactTable from '../components/ReactTable';
import {
  usePoDetailReactTableCols,
  usePoReceiveReactTableCols,
} from '../hooks/PoCols';
import fetchFromAthena from '../functions/FetchFromAthena'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%',
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' },
}));

export default function PoDetail(props) {
  const classes = useStyles();
  const { credentials } = props.fetchInitialData;
  const [state, setstate] = useState({
    showTotals: false,
  });
  const [sourceData, setSourceData] = useState(null);
  const [tabVal, setTabVal] = useState(0);
  const [curPoPrelease, setPoRelease] = useState(props.poHeaderData.PO_RELEASE);
  const [moreData, setMoreData] = useState(null);

  const poDetailReactTableCols = usePoDetailReactTableCols();
  const poReceiveReactTableCols = usePoReceiveReactTableCols();

  const getSourceData = async (company, poNum, poRelease, poCode, lineNbr) => {
    try {

      console.log({
        company,
        poNum,
        poRelease,
        poCode,
        lineNbr,
      })
      const resp = await fetchFromAthena({
        queryName: 'po_line_source_read',
        headers: {
          company,
          poNum,
          poRelease,
          poCode,
          lineNbr,
        },
        accessToken: credentials.user.accessToken,
        credentials: credentials
      })
      // const resp = await LambdaFetch(
      //   'po',
      //   'post',
      //   credentials.user.accessToken,
      //   JSON.stringify({
      //     action: 'get-source',
      //     company,
      //     poNum,
      //     poRelease,
      //     poCode,
      //     lineNbr,
      //   }),
      //   '',
      //   credentials
      // );
      const data = resp.map((row) => {
        return {
          ...row,
          DOCUMENT:
            row.COMPANY && row.SOURCE_DOC_N ? (
              <div
                className="editLink"
                onClick={() => {
                  const win = window.open(
                    `/requisition-inquiry?company=${row.COMPANY}&req=${row.SOURCE_DOC_N}`,
                    '_blank'
                  );
                  win.focus();
                }}
              >
                {row.SOURCE_DOC_N}
              </div>
            ) : (
              ''
            ),
        };
      });
      setSourceData(data);
    } catch (e) {
      console.log(e);
    }
  };
  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1;

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    );
  };
  const getPoReleaseData = async (release) => {
    await props.updateRelease(release);
    setPoRelease(release);
  };

  const printTotalValue = (dataObj, lookup, description, type) => {
    return (
      <div style={{ borderBottom: 'dotted #BFBFBF 1px' }}>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal} style={{ float: 'right' }}>
          {`${
            type === 'currency'
              ? currencyTextFormat(dataObj[lookup])
              : dataObj[lookup]
          }`}
        </span>
      </div>
    );
  };
  const viewRecLines = async (recNum, comp) => {
    try {

      const resp = await fetchFromAthena({
        queryName: 'po_recline_read',
        headers: {
          company: comp,
          recNum: recNum,
        },
        accessToken: credentials.user.accessToken,
        credentials: credentials
      })

      // const resp = await LambdaFetch(
      //   'po',
      //   'post',
      //   credentials.user.accessToken,
      //   JSON.stringify({
      //     action: 'get-recline',
      //     company: comp,
      //     recNum: recNum,
      //   }),
      //   '',
      //   credentials
      // );
      setMoreData(resp);
    } catch (e) {
      console.log(e);
    }
  };

  const { poHeaderData, poDetailData, poReceive, poReleases } = props;
  const poDetailLineData = poDetailData.map((p) => {
    return {
      ...p,
      SOURCE: p.SOURCE_COUNT ? (
        <div
          className="editLink"
          onClick={() =>
            getSourceData(
              p.COMPANY,
              p.PO_NUMBER,
              p.PO_RELEASE,
              p.PO_CODE,
              p.LINE_NBR
            )
          }
        >
          {'view'}
        </div>
      ) : (
        ''
      ),
    };
  });
  const receiveData = props.poReceive.map((r) => {
    return {
      ...r,
      REC_LINE_VIEW: r.LINE_CNT ? (
        <div
          className="editLink"
          onClick={() => {
            viewRecLines(r.REC_NUMBER, r.COMPANY);
          }}
        >{`${r.REC_NUMBER}(${r.LINE_CNT})`}</div>
      ) : (
        ''
      ),
    };
  });
  return (
    <>
      <DialogTable
        isOpen={!!moreData}
        handleClose={() => setMoreData(null)}
        tableData={moreData}
        cols={poRecLineReactTableCols}
        fileName={`po_rec_lines`}
        noFooter
        reactTable
      />

      <DialogTable
        isOpen={!!sourceData}
        handleClose={() => setSourceData(null)}
        tableData={sourceData}
        cols={poSourceLineReactTableCols}
        fileName={`line_detail`}
        reactTable
      />
      <Dialog
        open={state.showTotals}
        TransitionComponent={Transition}
        maxWidth="lg"
        scroll="body"
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  margin: '0.5rem auto',
                }}
              >
                {printValue(poHeaderData, 'COMPANY', 'Company')}
                {printValue(poHeaderData, 'PO_NUMBER', 'PO')}
                {printValue(poHeaderData, 'NBR_LINES', 'Number PO Lines')}
              </div>
            </Grid>
            <Grid item xs={12}>
              {printTotalValue(
                poHeaderData,
                'GOODS_TOTAL',
                'Goods Total',
                'currency'
              )}
              {printTotalValue(
                poHeaderData,
                'OPEN_PO_AMT',
                'Open Po Amount',
                'currency'
              )}
              {printTotalValue(
                poHeaderData,
                'PO_ADD_ON_COST',
                'PO Add-On Cost Total',
                'currency'
              )}
              {printTotalValue(
                poHeaderData,
                'PO_TAX_TOTAL',
                'PO Tax Total',
                'currency'
              )}
              {printTotalValue(
                poHeaderData,
                'ISSUED_PO_TOTAL',
                'Issued PO Total',
                'currency'
              )}
              {printTotalValue(
                poHeaderData,
                'GOODS_TOTAL',
                'Goods Total',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'PO_VEN_ADD_ON_COST',
                'PO Vendor Add-On Cost',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'PO_VEN_INV_TAX',
                'PO Vendor Invoiced Tax',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'OTHER_VEN_ADD_ON_COST',
                'Other Vendor Add-On Cost',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'OTHER_VEN_INV_TAX',
                'Other Vendor Invoiced Tax',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'ACCRUED_TAX',
                'Accrued Tax',
                'currency'
              )}

              {printTotalValue(
                poHeaderData,
                'PO_GRAND_TOTAL',
                'PO Grand Total',
                'currency'
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setstate({ ...state, showTotals: false })}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        style={{ marginTop: '1rem' }}
        elevation={props.inverseElevation ? 0 : 2}
      >
        <CardContent style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              marginTop: '0.5rem',
              justifyContent: 'flex-start',
              gap: '50px',
              alignItems: 'flex-end',
            }}
          >
            <div>
              <Typography variant="h5">
                {`Company: ${poHeaderData.COMPANY}`}
              </Typography>
              <Typography variant="subtitle1">
                <span>{`PO Number: ${poHeaderData.PO_NUMBER}`}</span>
                <span
                  style={{ marginLeft: '0.5rem' }}
                >{`Rev:${poHeaderData.PO_REVISION}`}</span>
              </Typography>
              <Typography variant="subtitle2">{`Vendor: ${
                poHeaderData.VENDOR_VNAME
              } ${
                poHeaderData.VENDOR && `(${poHeaderData.VENDOR.trim()})`
              }`}</Typography>
            </div>
            <div style={{ width: '150px' }}>
              <TextField
                value={curPoPrelease}
                onChange={(e) => getPoReleaseData(e.target.value)}
                select
                stlye={{ width: '150px' }}
                fullWidth
                label="Release"
                variant="filled"
                size="small"
              >
                {poReleases.map((r) => (
                  <MenuItem key={r.PO_RELEASE} value={r.PO_RELEASE}>
                    {r.PO_RELEASE}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div style={{ marginTop: '1rem' }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="space-evenly"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} sm={6}>
                      {printValue(poHeaderData, 'NAME', 'Buyer', 'BUYER_CODE')}
                      {printValue(poHeaderData, 'PO_DATE', 'PO Date')}

                      {printValue(poHeaderData, 'PO_CODE', 'PO Code')}
                      {printValue(
                        poHeaderData,
                        'PURCH_FR_LOC',
                        'Purchase From'
                      )}
                      {printValue(poHeaderData, 'SH_NAME', 'Ship To')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printValue(poHeaderData, 'LOCATION', 'Location')}
                      {printValue(
                        poHeaderData,
                        'DFLT_DL_DATE',
                        'Delivery Date'
                      )}
                      {printValue(poHeaderData, 'CURRENCY_CODE', 'Currency')}

                      {printValue(poHeaderData, 'CLOSED_FL', 'Closed Flag')}

                      {printValue(poHeaderData, 'CANCELLED_FL', 'Cancled Flag')}
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        size="small"
                        color="primary"
                        style={{ marginTop: '0.5rem' }}
                        onClick={() => setstate({ ...state, showTotals: true })}
                        variant="contained"
                      >
                        View Totals
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <AntTabs
              value={tabVal}
              color={credentials.primaryAppColor}
              handleChange={(event, newValue) => {
                setTabVal(newValue);
              }}
              tabs={['Lines', 'Recieving']}
            />

            <div style={{ marginTop: '1rem' }}>
              {tabVal === 0 && (
                <ReactTable
                  cols={poDetailReactTableCols}
                  data={poDetailLineData}
                />
              )}
              {tabVal === 1 && (
                <ReactTable cols={poReceiveReactTableCols} data={receiveData} />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
