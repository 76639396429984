import React from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { CommentsDisabledOutlined, DisplaySettingsRounded } from '@mui/icons-material'

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true
}

const csvExporter = new ExportToCsv(csvOptions)

export default function ReactTable (props) {
  const handleExportRows = rows => {
    if(rows.length === 0) return 
    const displayRows = rows.map((row) => {
      Object.keys(row.original).forEach(header => {
        if(typeof row.original[header] === 'object'){
          row.original[header] = ''
        }
      })
     return  row.original
    })

     csvExporter.generateCsv(displayRows);
  }

  const data = props.data
    ? props.data.map(row => {
        Object.keys(row).forEach(function (key, index) {
          if (row[key] === null) {
            row[key] = ''
          }
        })

        return row
      })
    : []

  return (
    <MaterialReactTable
      columns={props.cols.map(r => {
        return {...r, size:1}
      })}
      data={data}
      initialState={{ showColumnFilters: true, density: 'compact' }}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      enableStickyHeader
      enableFullScreenToggle={false}
      showSkeletons={true}
      enableFilterRankedResults={false}
      enableGlobalFilter={false} //turn off a feature
      state={{
        isLoading: props.isLoading ? true : false,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton
            onClick={() => {
              handleExportRows(table.getPrePaginationRowModel().rows)
            }}
            icon={<CloudDownloadIcon />}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
    />
  )
}
