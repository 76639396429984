import AthenaParameterTransformation from '../utils/AthenaParameterTransformation';
import LambdaFetch from './FetchFromLambda';

export default async function fetchFromAthena (params) {
    const {queryName, headers, pageSize = 9999, accessToken, credentials} = params;

  try {
    const parameters = AthenaParameterTransformation(queryName, headers)
    const fetchBody = {
      dashboard_name: queryName,
      ...parameters,
      pageSize: pageSize,
      roles: 'app_user,all-access,default'
    }
    const resp = await LambdaFetch(
      'dashboard-trigger',
      'post',
      accessToken,
      JSON.stringify(fetchBody),
      '',
      credentials
    )

    return resp.data.response.body.data === '[]' ? [] : resp.data.response.body.data
  } catch (e) {
    console.log(e)
    return false
  }
}
